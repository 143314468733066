<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("Answers") }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeFilterMainCredentials('surveys')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSurveys"
                    v-bind:label="$t('survey')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_surveys"
                    :loading="isSearching['surveys']"
                    :items="filtered_surveys"
                    hide-no-data
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeFilterMainCredentials('components')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.idComponents"
                    v-bind:label="`${this.$t('component')}`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_components"
                    :loading="isSearching['components']"
                    :items="filtered_components"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('questions')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.idQuestions"
                    v-bind:label="`${this.$t('Question')}`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_questions"
                    :loading="isSearching['questions']"
                    :items="filtered_questions"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.idSPVs"
                    v-bind:label="`SPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_spvs"
                    :loading="isSearching['spvs']"
                    :items="filtered_spvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('spvs')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.idRoutes"
                    v-bind:label="$t('route')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_routes"
                    :loading="isSearching['routes']"
                    :items="filtered_routes"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.idGPVs"
                    v-bind:label="`GPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_gpvs"
                    :loading="isSearching['gpvs']"
                    :items="filtered_gpvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.companyCodes"
                    :label="$t('Company')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_companies"
                    :loading="isSearching['companies']"
                    :items="filtered_companies"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <v-btn
              color="warning"
              dark
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!showEmpty">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefRouteCompliance"
              id="datatable_reporting_puntps"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
        <b-alert v-else variant="warning" class="m-0 mt-4 p-2 my-alert" show>
          <i> No hay datos....</i>
        </b-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ReportingAnswers",
  data: function() {
    return {
      visitTypes: [],
      VISIT_TYPES: {},
      filterlist_fetch_url: "reporting/surveyanswers/getfilterlist",
      filter_main_credentials_fetch_url:
        "reporting/columna/getfiltermaincredentials",
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      select_startDate_menu: false,
      select_endDate_menu: false,
      isSearching: {},
      search_brands: null,
      filtered_brands: [],
      search_surveys: null,
      filtered_surveys: [],
      search_spvs: null,
      filtered_spvs: [],
      search_gpvs: null,
      filtered_gpvs: [],
      search_routes: null,
      filtered_routes: [],
      search_companies: null,
      filtered_companies: [],
      search_components: null,
      filtered_components: [],
      search_questions: null,
      filtered_questions: [],
      filterMainCredential: {
        companyCodes: [],
        brandId: null,
        date: {
          from: null,
          to: null
        },
        idSurveys: null,
        idComponents: [],
        idQuestions: [],
        idSPVs: [],
        idRoutes: [],
        idGPVs: []
      },

      filterable_col_list: [
        "route",
        "date",
        "user",
        "pos",
        "address_label",
        "chain",
        "subchain",
        "sub2chain",
        "channel",
        "subchannel",
        "sub2channel",
        "brand"
      ],
      filterCriteria: {
        route: {},
        date: {},
        user: {},
        pos: {},
        address_label: "",
        chain: {},
        subchain: {},
        sub2chain: {},
        channel: {},
        subchannel: {},
        sub2channel: {},
        brand: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      totaldata: 0,
      data: []
    };
  },
  watch: {
    async search_components(val) {
      this.searchValues("components", val);
    },
    async search_questions(val) {
      this.searchValues("questions", val);
    },
    async search_companies(val) {
      this.searchValues("companies", val);
    },
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_surveys(val) {
      this.searchValues("surveys", val);
    },
    async search_routes(val) {
      this.searchValues("routes", val);
    },
    async search_spvs(val) {
      this.searchValues("spvs", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    }
  },
  computed: {
    showEmpty() {
      let ret = true;
      if (this.data.length > 0) {
        ret = false;
      }
      return ret;
    },
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={value =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_chain() {
      return {
        key: "chain",
        title: this.$t("chain"),
        field: "chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subchain() {
      return {
        key: "subchain",
        title: "Sub " + this.$t("chain"),
        field: "subchain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_sub2chain() {
      return {
        key: "sub2chain",
        title: "Sub-Sub " + this.$t("chain"),
        field: "sub2chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_channel() {
      return {
        key: "channel",
        title: this.$t("channel"),
        field: "channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subchannel() {
      return {
        key: "subchannel",
        title: "Sub " + this.$t("channel"),
        field: "subchannel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchannel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchannel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchannel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchannel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchannel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_sub2channel() {
      return {
        key: "sub2channel",
        title: "Sub-Sub " + this.$t("channel"),
        field: "sub2channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brands"),
        field: "brand",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_survey() {
      return {
        key: "survey",
        title: this.$t("survey"),
        field: "survey",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey_name}</span>;
        }
      };
    },
    col_survey_component() {
      return {
        key: "survey_component",
        title: this.$t("component"),
        field: "survey_component",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey_component_name}</span>;
        }
      };
    },
    col_pro_mat_component() {
      return {
        key: "pro_mat_component",
        title: `${this.$t("Product")}/${this.$t("Material")}/${this.$t(
          "Others"
        )}`,
        field: "pro_mat_component",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.pro_mat_component_name}</span>;
        }
      };
    },
    col_pro_mat_family() {
      return {
        key: "pro_mat_family",
        title: `${this.$t("brands.family")}`,
        field: "pro_mat_family",
        align: "left",
        width: 200
      };
    },
    col_pro_mat_sub_family() {
      return {
        key: "pro_mat_sub_family",
        title: `Sub ${this.$t("brands.family")}`,
        field: "pro_mat_sub_family",
        align: "left",
        width: 200
      };
    },
    col_surveyQuestionId() {
      return {
        key: "surveyQuestionId",
        title: `ID ${this.$t("Question")}`,
        field: "surveyQuestionId",
        align: "left",
        width: 200
      };
    },
    col_survey_question() {
      return {
        key: "survey_question",
        title: `${this.$t("Question")}`,
        field: "survey_question",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey_question_name}</span>;
        }
      };
    },
    col_value_answer() {
      return {
        key: "value_answer",
        title: `${this.$t("Value answer")}`,
        field: "value_answer",
        align: "left",
        width: 180
      };
    },
    col_maxPSPoints() {
      return {
        key: "maxPSPoints",
        title: `${this.$t("Max PS Points")}`,
        field: "maxPSPoints",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_score() {
      return {
        key: "score",
        title: `${this.$t("PS Points answer")}`,
        field: "score",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_lastPSPoints() {
      return {
        key: "lastPSPoints",
        title: `${this.$t("PS Points Previous answer")}`,
        field: "lastPSPoints",
        align: "left",
        sortBy: "",
        width: 180
      };
    },
    col_totalVisitCount() {
      return {
        key: "totalVisitCount",
        title: `Total Visit Count`,
        field: "totalVisitCount",
        align: "left",
        width: 150
      };
    },
    col_visitIndex() {
      return {
        key: "visitIndex",
        title: `Visit Number`,
        field: "visitIndex",
        align: "left",
        width: 150
      };
    },
    col_pv_uv() {
      return {
        key: "pv_uv",
        title: `PV/UV`,
        field: "pv_uv",
        align: "left",
        width: 150
      };
    },
    col_totalVisitSurveyCount() {
      return {
        key: "totalVisitSurveyCount",
        title: `Total Visit Count`,
        field: "totalVisitSurveyCount",
        align: "left",
        width: 150
      };
    },
    col_visitSurveyIndex() {
      return {
        key: "visitSurveyIndex",
        title: `Visit Number`,
        field: "visitSurveyIndex",
        align: "left",
        width: 150
      };
    },
    col_survey_pv_uv() {
      return {
        key: "survey_pv_uv",
        title: `PV/UV`,
        field: "survey_pv_uv",
        align: "left",
        width: 150
      };
    },

    columns() {
      return [
        this.col_route,
        this.col_date,
        this.col_userId,
        this.col_user,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_chain,
        this.col_subchain,
        this.col_sub2chain,
        this.col_channel,
        this.col_subchannel,
        this.col_sub2channel,
        this.col_brand,
        this.col_survey,
        this.col_survey_component,
        this.col_pro_mat_component,
        this.col_pro_mat_family,
        this.col_pro_mat_sub_family,
        this.col_surveyQuestionId,
        this.col_survey_question,
        this.col_value_answer,
        this.col_maxPSPoints,
        this.col_score,
        this.col_lastPSPoints,
        // this.col_totalVisitCount,
        // this.col_visitIndex,
        // this.col_pv_uv,
        this.col_totalVisitSurveyCount,
        this.col_visitSurveyIndex,
        this.col_survey_pv_uv
        // {
        //   key: "actions",
        //   title: this.$t("edititem"),
        //   field: "actions",
        //   align: "center",
        //   width: 100,
        //   fixed: "right",
        //   renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     return (
        //       <div>
        //         <v-icon
        //           medium
        //           color="primary"
        //           class="mr-2"
        //           on-click={() => this.onItemEdit(row)}
        //         >
        //           mdi-pencil
        //         </v-icon>
        //       </div>
        //     );
        //   },
        // },
      ];
    }
  },
  methods: {
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "surveys") {
        this.filtered_surveys = resp.data;
      } else if (fieldname === "spvs") {
        this.filtered_spvs = resp.data;
      } else if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "routes") {
        this.filtered_routes = resp.data;
      } else if (fieldname === "companies") {
        this.filtered_companies = resp.data;
      } else if (fieldname === "components") {
        this.filtered_components = resp.data;
      } else if (fieldname === "questions") {
        this.filtered_questions = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },
    onChangeDates() {
      if (
        this.filterMainCredential.startDate > this.filterMainCredential.endDate
      ) {
        this.filterMainCredential.endDate = this.filterMainCredential.startDate;
      }
    },
    async onClickFilter() {
      if (this.$refs.form.validate()) {
        this.getDataFromApi();
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "sumOfScoreOfCurrentVisit" ||
        cancelFilterKey === "sumOfObjValue" ||
        cancelFilterKey === "sumOfDiffScoreVsLastVisit"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_reporting_puntps"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async getDataFromApi() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_puntps .filterable-column"
      });
      let fetch_url = "reporting/surveyanswers";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.data = resp.data;
        this.totaldata = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_puntps .filterable-column"
      });
      console.log(query_params_string);
      let fetch_url = "reporting/surveyanswers/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      console.log("item - ", item);
      // this.selected = this.createSelectedModel(item);
      // this.editedIndex = this.selected.id;
      // this.showEdit = true;
    },
    async init() {
      this.searchValues("brands", "");
      this.searchValues("surveys", "");
      this.searchValues("components", "");
      this.searchValues("questions", "");
      this.searchValues("spvs", "");
      this.searchValues("gpvs", "");
      this.searchValues("routes", "");
      this.searchValues("companies", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
